import { LumiDB, SourceFileMetadata } from "@lumidb/lumidb";
import {
    Box3,
    BoxGeometry,
    Clock,
    DepthTexture,
    EdgesGeometry,
    Euler,
    EventDispatcher,
    Group,
    LineBasicMaterial,
    LineSegments,
    Mesh,
    MeshBasicMaterial,
    OrthographicCamera,
    PerspectiveCamera,
    PlaneGeometry,
    Raycaster,
    Scene,
    SphereGeometry,
    UnsignedIntType,
    Vector2,
    Vector3,
    WebGLRenderer,
    WebGLRenderTarget,
} from "three";
import { MapControls } from "three/addons/controls/MapControls.js";
import { EDLMaterial } from "./edl-material";
import { FPSControls } from "./fps-controls";
import { DEFAULT_POINT_MATERIAL, PointChunk } from "./point-chunk";
import { ColorMode } from "./point-material";

const CAM_NEAR = 0.5;
const CAM_FAR = 100_000.0;

const POSE_GEOMETRY = new SphereGeometry(0.5, 16, 8);
const POSE_MATERIAL_GREEN = new MeshBasicMaterial({ color: "#00ff00", opacity: 0.5, transparent: true });
const POSE_MATERIAL_RED = new MeshBasicMaterial({
    color: "#ff0000",
    opacity: 0.5,
    transparent: true,
    depthTest: false,
});

export type FetchInfo = {
    pointCount: number;
    elapsed: number;
    queryPolygon: number[][][];
    queryArea: number;
    byteSize: number;
    seenIndices: Set<number>;
    seenClassifications: Set<number>;
    bounds: Box3;
};

interface FetchPointsOptions {
    lumidb: LumiDB;
    tableName: string;
    queryPolygon: number[][][];
    metaFilter: number[] | null;
    classificationMask: number;
    pointLimit: number;
    maxDensity: number | null;
    why: string;
}

export class Viewer extends EventDispatcher<{ fetchCompleted: FetchInfo }> {
    // canvasElement: HTMLCanvasElement;
    camera: PerspectiveCamera;
    renderer: WebGLRenderer;
    scene: Scene;
    controls: MapControls | FPSControls;
    fpsControls: FPSControls;
    mapControls: MapControls;

    private queryPolygon: null | number[][][] = null;

    renderTarget: WebGLRenderTarget;
    sceneOrtho: Scene;
    cameraOrtho: OrthographicCamera;

    edlMaterial: EDLMaterial;

    width: number;
    height: number;

    addedChunks: PointChunk[] = [];
    sensorPoses: Group = new Group();

    boundingMeshes: [LineSegments, SourceFileMetadata][] = [];

    loadCount = 0;
    onLoadCountUpdated: null | ((count: number) => void) = null;

    onCameraPositionChanged:
        | null
        | ((params: {
              offset: number[];
              camera: { mode: "fps" | "map"; position: number[]; rotation: number[]; autoRotate: boolean };
          }) => void) = null;

    cameraInitialized = false;

    lastFetchID = 0;
    lastFetchAbortController: AbortController | null = null;

    clock = new Clock();

    mouseDown = false;
    mouseStationary = true;
    pickedSensorPoses = new Set<number>();

    constructor() {
        super();

        this.renderer = new WebGLRenderer({
            alpha: true,
        });

        this.width = Math.max(1, this.renderer.domElement.clientWidth);
        this.height = Math.max(1, this.renderer.domElement.clientHeight);

        this.camera = new PerspectiveCamera(75, this.width / this.height, CAM_NEAR, CAM_FAR);
        this.scene = new Scene();

        // Use a Z-up coordinate system
        this.camera.up.set(0, 0, 1);

        this.renderer.setSize(this.width, this.height);

        const depthTexture = new DepthTexture(this.width, this.height, UnsignedIntType);

        this.renderTarget = new WebGLRenderTarget(this.width, this.height, { depthTexture: depthTexture });

        this.edlMaterial = new EDLMaterial(this.renderTarget.texture, depthTexture, CAM_NEAR, CAM_FAR);

        this.edlMaterial.setResolution(this.width, this.height);

        this.sceneOrtho = new Scene();
        this.sceneOrtho.add(new Mesh(new PlaneGeometry(2, 2), this.edlMaterial));
        this.cameraOrtho = new OrthographicCamera(-1, 1, 1, -1, 0, 1);

        this.mapControls = new MapControls(this.camera, this.renderer.domElement);
        this.mapControls.autoRotate = true;
        this.mapControls.zoomToCursor = true;
        this.mapControls.minDistance = 5.0;
        this.mapControls.maxPolarAngle = Math.PI / 2;
        this.mapControls.addEventListener("end", () => {
            this.controls.autoRotate = false;
            this.positionChanged();
        });
        this.mapControls.addEventListener("change", () => {
            this.positionChanged();
        });

        this.fpsControls = new FPSControls(this.camera, this.renderer.domElement);
        this.fpsControls.addEventListener("change", () => {
            this.positionChanged();
        });

        this.controls = this.mapControls;

        this.resetCamera();

        this.scene.add(this.sensorPoses);

        this.renderer.domElement.addEventListener("pointermove", (event) => {
            const poseId = this.pickPose(event);

            if (poseId) {
                this.renderer.domElement.style.cursor = "pointer";
            } else {
                this.renderer.domElement.style.cursor = "default";
            }

            if (this.mouseDown) {
                this.mouseStationary = false;
            }
        });

        this.renderer.domElement.addEventListener("pointerdown", (event) => {
            if (event.button !== 0) {
                return;
            }

            this.mouseDown = true;
            this.mouseStationary = true;
        });

        this.renderer.domElement.addEventListener("pointerup", (event) => {
            if (event.button !== 0) {
                return;
            }
            if (this.mouseStationary) {
                const poseId = this.pickPose(event);
                if (poseId) {
                    console.log("clicked on sensor", poseId);
                    if (this.pickedSensorPoses.has(poseId)) {
                        this.pickedSensorPoses.delete(poseId);
                    } else {
                        this.pickedSensorPoses.add(poseId);
                    }
                    DEFAULT_POINT_MATERIAL.setFilteredPoseIDs(this.pickedSensorPoses);
                    this.updateSensorMaterials();
                }
            }
        });
    }

    updateMousePos(event: MouseEvent, output: Vector2) {
        const rect = this.renderer.domElement.getBoundingClientRect();
        output.x = ((event.clientX - rect.x) / rect.width) * 2 - 1;
        output.y = -((event.clientY - rect.y) / rect.height) * 2 + 1;
        return output;
    }

    pickPose(event: MouseEvent) {
        if (this.sensorPoses.children.length === 0) {
            return null;
        }
        const raycaster = new Raycaster();
        const mouse = this.updateMousePos(event, new Vector2());
        raycaster.setFromCamera(mouse, this.camera);

        let poseId: number | null = null;
        let closest = Infinity;

        for (const intersection of raycaster.intersectObjects(this.sensorPoses.children)) {
            if (intersection.distance < closest) {
                closest = intersection.distance;
                poseId = intersection.object.userData.poseId as number;
            }
        }

        return poseId;
    }

    setControlMode(mode: "map" | "fps") {
        if (mode === "fps") {
            this.mapControls.enabled = false;
            this.fpsControls.bind();
            this.controls = this.fpsControls;
        } else {
            this.fpsControls.unbind();
            this.mapControls.enabled = true;
            this.controls = this.mapControls;
        }
    }

    renderRequested = false;
    requestRender() {
        if (!this.renderRequested) {
            this.renderRequested = true;

            // TODO: should only render when something changes
            requestAnimationFrame(() => {
                this.render();
            });
        }
    }

    positionChanged() {
        if (!this.cameraInitialized) {
            return;
        }

        const pos = this.camera.position.toArray();
        const rot = this.camera.rotation.toArray();
        this.onCameraPositionChanged?.({
            camera: {
                mode: this.controls === this.fpsControls ? "fps" : "map",
                position: [pos[0], pos[1], pos[1]],
                rotation: [rot[0], rot[1], rot[2]],
                autoRotate: this.controls.autoRotate,
            },
            offset: this.getCurrentOffset().toArray(),
        });
    }

    private render() {
        this.renderRequested = false;
        this.controls.update(this.clock.getDelta());

        // Render the points to a offscreen buffer
        this.renderer.setRenderTarget(this.renderTarget);
        this.renderer.render(this.scene, this.camera);

        // Render the the buffer to the screen (using EDL)
        this.renderer.setRenderTarget(null);
        this.renderer.render(this.sceneOrtho, this.cameraOrtho);

        this.requestRender();
    }

    clearContent() {
        for (const p of this.addedChunks) {
            this.scene.remove(p.points);
            p.points.geometry.dispose();
        }
    }

    debounceFetchTimer: number = 0;

    fetchPoints({
        lumidb,
        classificationMask: classFilter,
        metaFilter,
        pointLimit,
        maxDensity,
        queryPolygon,
        why,
        tableName,
    }: FetchPointsOptions) {
        if (metaFilter === null) {
            console.log(`no filter, skipping fetch (${why})`);
            return;
        }
        if (this.debounceFetchTimer) {
            clearTimeout(this.debounceFetchTimer);
            this.debounceFetchTimer = 0;
        }
        this.debounceFetchTimer = setTimeout(async () => {
            const fetchID = performance.now();
            if (this.lastFetchAbortController) {
                console.warn("cancel previous query", this.lastFetchID);
                this.lastFetchAbortController.abort();
            }

            const classFilterArray = [];
            for (let i = 0; i < 32; i++) {
                if (classFilter & (1 << i)) {
                    classFilterArray.push(i);
                }
            }

            const abortController = new AbortController();
            console.log("fetchPoints", why, queryPolygon, pointLimit);
            const start = performance.now();
            this.loadCount++;
            this.onLoadCountUpdated?.(this.loadCount);
            try {
                this.lastFetchID = fetchID;
                this.lastFetchAbortController = abortController;
                const apiPoints = await PointChunk.loadFromAPI(
                    lumidb,
                    tableName,
                    queryPolygon,
                    pointLimit,
                    maxDensity,
                    metaFilter,
                    classFilterArray,
                    abortController.signal,
                );

                if (this.lastFetchAbortController === abortController) {
                    this.lastFetchAbortController = null;
                }

                if (fetchID === this.lastFetchID) {
                    this.dispatchEvent({
                        type: "fetchCompleted",
                        pointCount: apiPoints.pointCount,
                        elapsed: performance.now() - start,
                        queryPolygon: queryPolygon,
                        queryArea: apiPoints.queryArea,
                        byteSize: apiPoints.byteSize,
                        seenIndices: apiPoints.seenIndices,
                        seenClassifications: apiPoints.seenClassifications,
                        bounds: new Box3(
                            new Vector3().fromArray(apiPoints.bounds.min),
                            new Vector3().fromArray(apiPoints.bounds.max),
                        ),
                    });

                    this.clearContent();
                    this.addedChunks.push(apiPoints);
                    this.scene.add(apiPoints.points);
                    this.queryPolygon = queryPolygon;
                    this.updateBoundsCenter();
                    DEFAULT_POINT_MATERIAL.setIntensityRange(apiPoints.intensityRange[0], apiPoints.intensityRange[1]);

                    // move the sensor poses to go from real world coordinates to the offset coordinates
                    this.sensorPoses.position.copy(apiPoints.offset.clone().multiplyScalar(-1));
                } else {
                    console.warn("fetch result is too old, discarding the response", fetchID, this.lastFetchID);
                    return;
                }
            } finally {
                this.loadCount--;
                this.onLoadCountUpdated?.(this.loadCount);
            }
        }, 300);
        return this.debounceFetchTimer;
    }

    setSizeDebounceHandle = 0;
    setSizeDebounced(width: number, height: number) {
        if (this.setSizeDebounceHandle) {
            clearTimeout(this.setSizeDebounceHandle);
            this.setSizeDebounceHandle = 0;
        }
        this.setSizeDebounceHandle = setTimeout(() => {
            this.setSize(width, height);
        }, 200);
    }

    setSize(width: number, height: number) {
        console.log("setSize", width, height);
        this.width = width;
        this.height = height;

        this.edlMaterial.setResolution(width, height);

        this.renderer.setSize(width, height);
        this.renderTarget.setSize(width, height);

        this.camera.aspect = width / height;
        this.camera.updateProjectionMatrix();
    }

    setColorMode(colorMode: ColorMode) {
        DEFAULT_POINT_MATERIAL.setColorMode(colorMode);
    }

    setClassificationMask(mask: number) {
        DEFAULT_POINT_MATERIAL.setClassificationMask(mask);
    }

    resetCamera() {
        this.cameraInitialized = true;
        this.controls.reset();
        this.camera.position.set(0, -400, 300);
        this.camera.lookAt(new Vector3(0, 0, 0));
        this.positionChanged();
    }

    restoreCamera(position: number[], rotation: number[], autoRotate: boolean, mode: "fps" | "map") {
        this.setControlMode(mode);
        this.camera.position.fromArray(position);
        this.camera.setRotationFromEuler(new Euler(rotation[0], rotation[1], rotation[2]));
        this.controls.autoRotate = autoRotate;
    }

    toggleAnimation() {
        this.controls.autoRotate = !this.controls.autoRotate;
    }

    adjustPointSize(delta: number) {
        DEFAULT_POINT_MATERIAL.adjustPointSize(delta);
    }

    updateBoundsCenter() {
        const center = this.getCurrentOffset();

        for (const [mesh, fileInfo] of this.boundingMeshes) {
            mesh.position.set(
                -center.x + (fileInfo.transformed_aabb.min[0] + fileInfo.transformed_aabb.max[0]) / 2,
                -center.y + (fileInfo.transformed_aabb.min[1] + fileInfo.transformed_aabb.max[1]) / 2,
                0,
            );
        }
    }

    addBoundingMeshes(meta: Map<number, SourceFileMetadata>) {
        for (const [_, file] of meta) {
            const box = new Box3(
                new Vector3(file.transformed_aabb.min[0], file.transformed_aabb.min[1], file.transformed_aabb.min[2]),
                new Vector3(file.transformed_aabb.max[0], file.transformed_aabb.max[1], file.transformed_aabb.max[2]),
            );

            const size = box.getSize(new Vector3());

            const boxGeom = new BoxGeometry(size.x, size.y, size.z);

            const edgesGeom = new EdgesGeometry(boxGeom);

            const bounds = new LineSegments(edgesGeom, new LineBasicMaterial({ color: "green", depthWrite: false }));

            bounds.visible = false;

            this.boundingMeshes.push([bounds, file]);
            this.scene.add(bounds);
        }
    }

    updateHighlightedBoundaries(file_paths: string[]) {
        for (const [bounds, fileInfo] of this.boundingMeshes) {
            if (file_paths.includes(fileInfo.filename)) {
                bounds.visible = true;
            } else {
                bounds.visible = false;
            }
        }
    }

    private getCurrentOffset() {
        if (!this.queryPolygon) {
            return new Vector2(0, 0);
        }

        let maxX = -Infinity;
        let maxY = -Infinity;
        let minX = Infinity;
        let minY = Infinity;

        for (const p of this.queryPolygon) {
            for (const [x, y] of p) {
                maxX = Math.max(maxX, x);
                maxY = Math.max(maxY, y);
                minX = Math.min(minX, x);
                minY = Math.min(minY, y);
            }
        }

        return new Vector2((maxX + minX) / 2, (maxY + minY) / 2);
    }

    setSensorPoses(files: Map<number, SourceFileMetadata>) {
        // remove all existing sensor poses
        for (const mesh of this.sensorPoses.children) {
            this.sensorPoses.remove(mesh);
        }

        let i = 0;
        for (const [_, fileInfo] of files) {
            if (fileInfo.sensor_poses) {
                for (const pos of fileInfo.sensor_poses) {
                    const mesh = new Mesh(POSE_GEOMETRY, POSE_MATERIAL_GREEN);
                    mesh.position.fromArray(pos);
                    this.sensorPoses.add(mesh);
                    mesh.userData.poseId = i++;
                }
            }
        }
    }

    updateSensorMaterials() {
        for (const mesh of this.sensorPoses.children) {
            const poseId = mesh.userData.poseId as number;

            if (this.pickedSensorPoses.has(poseId)) {
                (mesh as Mesh).material = POSE_MATERIAL_RED;
            } else {
                (mesh as Mesh).material = POSE_MATERIAL_GREEN;
            }
        }
    }
}
