import { Camera, Controls, Raycaster, Vector2, Vector3 } from "three";

const UNIT_Z = new Vector3(0, 0, 1);

/**
 * A simple first person shooter style control scheme with mouse look and WASD movement.
 *
 * Inspired by three.js FirstPersonControls, see:
 * - https://threejs.org/docs/#examples/en/controls/FirstPersonControls
 * - https://github.com/mrdoob/three.js/blob/master/examples/jsm/controls/FirstPersonControls.js
 */
export class FPSControls extends Controls<{ change: unknown }> {
    camera: Camera;
    domElement: HTMLElement;

    baseMovementSpeed = 8.0;
    mouseSensitivity = 0.2;

    moveRight = false;
    moveLeft = false;
    moveForward = false;
    moveBack = false;
    moveUp = false;
    moveDown = false;
    speedBoost = false;

    isRotating = false;

    mousePrev = new Vector2();

    private readonly handleKeyDown: (e: KeyboardEvent) => void;
    private readonly handleKeyUp: (e: KeyboardEvent) => void;
    private readonly handlePointerDown: (e: PointerEvent) => void;
    private readonly handlePointerUp: (e: PointerEvent) => void;
    private readonly handlePointerCancel: (e: PointerEvent) => void;
    private readonly handlePointerMove: (e: PointerEvent) => void;
    private readonly handleWheel: (e: WheelEvent) => void;
    private readonly preventRightClick = (e: MouseEvent) => e.preventDefault();

    constructor(camera: Camera, domElement: HTMLElement) {
        super(camera, domElement);

        this.camera = camera;
        this.domElement = domElement;

        this.handleKeyDown = (event) => this.onKeyDown(event.code);
        this.handleKeyUp = (event) => this.onKeyUp(event.code);
        this.handlePointerDown = (event) => this.onClick(event, true);
        this.handlePointerUp = (event) => this.onClick(event, false);
        this.handlePointerCancel = (event) => this.onClick(event, false);
        this.handlePointerMove = (event) => this.onPointerMove(event);
        this.handleWheel = (event) => this.onWheel(event);
    }

    get movementSpeed() {
        return this.baseMovementSpeed + 2 * Math.sqrt(Math.max(0, this.camera.position.z));
    }

    get autoRotate() {
        return false;
    }

    set autoRotate(value: boolean) {
        // noop
    }

    bind() {
        window.addEventListener("keydown", this.handleKeyDown);
        window.addEventListener("keyup", this.handleKeyUp);
        this.domElement.addEventListener("pointerdown", this.handlePointerDown);
        this.domElement.addEventListener("pointerup", this.handlePointerUp);
        this.domElement.addEventListener("pointercancel", this.handlePointerCancel);
        this.domElement.addEventListener("pointermove", this.handlePointerMove);
        this.domElement.addEventListener("wheel", this.handleWheel);
        this.domElement.addEventListener("contextmenu", this.preventRightClick);
    }

    unbind() {
        window.removeEventListener("keydown", this.handleKeyDown);
        window.removeEventListener("keyup", this.handleKeyUp);
        this.domElement.removeEventListener("pointerdown", this.handlePointerDown);
        this.domElement.removeEventListener("pointerup", this.handlePointerUp);
        this.domElement.removeEventListener("pointercancel", this.handlePointerCancel);
        this.domElement.removeEventListener("pointermove", this.handlePointerMove);
        this.domElement.removeEventListener("wheel", this.handleWheel);
        this.domElement.removeEventListener("contextmenu", this.preventRightClick);
    }

    onWheel(event: WheelEvent) {
        this.updateMouse(event, this.mousePrev);
        // try to normalize the scroll speed a bit. TODO: use event.deltaMode
        const dY = Math.sign(event.deltaY) * Math.sqrt(Math.abs(event.deltaY));
        const raycaster = new Raycaster();
        raycaster.setFromCamera(this.mousePrev, this.camera);

        const speed = 1 + 0.5 * Math.sqrt(Math.max(0, this.camera.position.z));
        this.camera.position.add(raycaster.ray.direction.normalize().multiplyScalar(-dY * speed));
        this.dispatchEvent({ type: "change" });
    }

    updateMouse(event: MouseEvent, output: Vector2) {
        const rect = this.domElement.getBoundingClientRect();
        output.x = ((event.clientX - rect.x) / rect.width) * 2 - 1;
        output.y = -((event.clientY - rect.y) / rect.height) * 2 + 1;
        return output;
    }

    onPointerMove(event: PointerEvent) {
        if (!this.isRotating) {
            return;
        }

        const mouse = this.updateMouse(event, new Vector2());
        const dX = (mouse.x - this.mousePrev.x) * 2 * Math.PI * this.mouseSensitivity;
        let dY = -(mouse.y - this.mousePrev.y) * 2 * Math.PI * this.mouseSensitivity;

        const cameraDir = this.camera.getWorldDirection(new Vector3());
        const pitchAngle = cameraDir.angleTo(UNIT_Z);

        // limit pitch to 0 .. PI to prevent going upside down
        if (pitchAngle + dY > Math.PI) {
            dY = Math.PI - pitchAngle;
        }
        if (pitchAngle + dY < 0) {
            dY = 0 - pitchAngle;
        }

        const left = cameraDir.cross(new Vector3(0, 0, 1)).normalize();
        this.camera.rotateOnWorldAxis(left, -dY);

        this.camera.rotateOnWorldAxis(UNIT_Z, -dX);

        this.updateMouse(event, this.mousePrev);
        this.dispatchEvent({ type: "change" });
    }

    onClick(event: PointerEvent, setTo: boolean) {
        if (event.button !== 0) {
            return;
        }

        if (setTo) {
            this.domElement.setPointerCapture(event.pointerId);
        } else {
            this.domElement.releasePointerCapture(event.pointerId);
        }

        this.isRotating = setTo;
        this.updateMouse(event, this.mousePrev);
    }

    reset() {
        // noop
    }

    get isMoving() {
        return this.moveForward || this.moveBack || this.moveLeft || this.moveRight || this.moveUp || this.moveDown;
    }

    update(delta: number) {
        if (!this.isMoving) {
            return;
        }

        const worldDir = this.camera.getWorldDirection(new Vector3());
        const left = new Vector3(0, 0, 1).cross(worldDir).normalize();
        const up = new Vector3().crossVectors(worldDir, left).normalize();

        const movement = new Vector3();

        if (this.moveForward) {
            movement.add(worldDir.clone().multiplyScalar(delta * this.movementSpeed));
        } else if (this.moveBack) {
            movement.add(worldDir.clone().multiplyScalar(-delta * this.movementSpeed));
        }

        if (this.moveLeft) {
            movement.add(left.clone().multiplyScalar(delta * this.movementSpeed));
        } else if (this.moveRight) {
            movement.add(left.clone().multiplyScalar(-delta * this.movementSpeed));
        }

        if (this.moveUp) {
            movement.add(up.clone().multiplyScalar(delta * this.movementSpeed));
        } else if (this.moveDown) {
            movement.add(up.clone().multiplyScalar(-delta * this.movementSpeed));
        }

        if (this.speedBoost) {
            movement.multiplyScalar(5);
        }

        this.camera.position.add(movement);
        this.dispatchEvent({ type: "change" });
    }

    private onKeyDown(k: string) {
        if (k === "KeyW" || k === "ArrowUp") {
            this.moveForward = true;
        }
        if (k === "KeyS" || k === "ArrowDown") {
            this.moveBack = true;
        }
        if (k === "KeyA" || k === "ArrowLeft") {
            this.moveLeft = true;
        }
        if (k === "KeyD" || k === "ArrowRight") {
            this.moveRight = true;
        }
        if (k === "KeyR" || k === "KeyE") {
            this.moveUp = true;
        }
        if (k === "KeyF" || k === "KeyQ") {
            this.moveDown = true;
        }
        if (k === "ShiftLeft") {
            this.speedBoost = true;
        }
        if (k === "Space") {
            this.moveUp = true;
        }
    }

    private onKeyUp(k: string) {
        if (k === "KeyW" || k === "ArrowUp") {
            this.moveForward = false;
        }
        if (k === "KeyS" || k === "ArrowDown") {
            this.moveBack = false;
        }
        if (k === "KeyA" || k === "ArrowLeft") {
            this.moveLeft = false;
        }
        if (k === "KeyD" || k === "ArrowRight") {
            this.moveRight = false;
        }
        if (k === "KeyR" || k === "KeyE") {
            this.moveUp = false;
        }
        if (k === "KeyF" || k === "KeyQ") {
            this.moveDown = false;
        }
        if (k === "ShiftLeft") {
            this.speedBoost = false;
        }
        if (k === "Space") {
            this.moveUp = false;
        }
    }
}
